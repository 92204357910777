import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const ImageCarousel = () => {
  return (
    <div className="image-carousel-wrapper">
      <Carousel className="image-carousel">
        <div>
          <img src="./image-carousel-1.jpg" alt="" />
        </div>
        <div>
          <img src="./image-carousel-7.jpg" alt="" />
        </div>
        <div>
          <img src="./image-carousel-8.jpg" alt="" />
        </div>
        <div>
          <img src="./image-carousel-4.jpg" alt="" />
        </div>
        <div>
          <img src="./image-carousel-5.jpg" alt="" />
        </div>
        <div>
          <img src="./image-carousel-6.jpg" alt="" />
        </div>
        <div>
          <img src="./image-carousel-9.jpg" alt="" />
        </div>
        <div>
          <img src="./image-carousel-2.jpg" alt="" />
        </div>
        <div>
          <img src="./image-carousel-3.jpg" alt="" />
        </div>
      </Carousel>
    </div>
  );
};

export default ImageCarousel;

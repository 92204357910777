import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <img
        className="footer-image"
        src="./barberSwirlHorizontal.png"
        alt=""
      ></img>
    </footer>
  );
};

export default Footer;

import React from "react";

const Section2 = () => {
  return (
    <section className="s2">
      <h2 className="s2-h2">Vad vi erbjuder</h2>
      <section className="section-price-wrapper">
        <div className="pricelist-items">
          <p>Herrklippning</p>
          <p>Skägg</p>
          <p>Herrklippning + Skägg</p>
          <p>Pensionär (65+)</p>
          <p>Skägg Pensionär</p>
          <p>Pensionär + Skägg</p>
          <p>Student (13-19)</p>
          <p>Barnklippning (6-12)</p>
        </div>
        <div className="pricelist-prices">
          <p className="price-color">390kr</p>
          <p className="price-color">390kr</p>
          <p className="price-color">700kr</p>
          <p className="price-color">300kr</p>
          <p className="price-color">300kr</p>
          <p className="price-color">550kr</p>
          <p className="price-color">350kr</p>
          <p className="price-color">300kr</p>
        </div>
      </section>
    </section>
  );
};

export default Section2;

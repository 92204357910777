import React from "react";

const Header = () => {
  return (
    <header className="header">
      <img src="./261barberHeroImageSmall.png" className="heroImage" alt="" />
    </header>
  );
};

export default Header;

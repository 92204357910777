import React from "react";

const Section1 = () => {
  return (
    <section className="s1">
      <h1 className="s1-h1">Öppettider</h1>
      <div>
        <p className="text-align-right">Tisdag: 16:00 - 20:00</p>
        <p className="text-align-right">Onsdag: 10:00 - 20:00</p>
        <p className="text-align-right">Torsdag: 10:00 - 20:00</p>
        <p className="text-align-right">Fredag: 10:00 - 20:00</p>
        <p className="text-align-right">Lördag: 10:00 - 14:00</p>
      </div>
      <div>
        <a
          href="https://book.wavy.nu/salon/fbe29742e6238ce895c2c697"
          className="booking-button"
        >
          Boka tid
        </a>
        <a href="mailto:info@261barber.se" className="booking-button">
          Skicka Mail
        </a>
      </div>

      <h2>Hitta till oss</h2>
      <div className="social-icons-wrapper">
        <a href="https://www.facebook.com/261-Herrfrisering-Barberare-112514843863640/">
          <img className="social-icons" src="./facebook-icon.png" alt="" />
        </a>
        <a href="https://www.instagram.com/261.barber">
          <img className="social-icons" src="./instagram-icon.png" alt="" />
        </a>
      </div>
      <p>Landskronavägen 22, 26173, Häljarp</p>
      <iframe
        className="google-maps"
        title="Google Maps Direction"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Landskronav%C3%A4gen%2022%2026173%20H%C3%A4ljarp+(261Barber)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
    </section>
  );
};

export default Section1;

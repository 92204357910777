import "./App.css";
import Header from "./components/Header";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Footer from "./components/Footer";
// import ContactForm from "./components/ContactForm";
import ImageCarousel from "./components/ImageCarousel";

function App() {
  return (
    <div>
      <Header />
      <div className="section-row-column">
        <Section1 />
        <Section2 />
      </div>
      <ImageCarousel />
      {/* <ContactForm /> */}
      <Footer />
    </div>
  );
}

export default App;
